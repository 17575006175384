/* VideoPlayer.css */

.video-container {
  display: flex;
    flex-direction: column;
    position: relative;
    width: '100%';
    height: '100%';
    overflow: hidden;
    border: 1px solid black;
  }
  
  .video-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: auto; /* Разрешение всех событий мыши на оверлее */
    z-index: 1; /* Размещаем поверх видео */
  }
  

  .gradient-container {
    position: relative;
    width: '100%';
    height: '100%';
    overflow: hidden;
  }
  

  .videoStyle {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    aspect-ratio: 16/9; 
  }
  

  @media (max-width: 991px) {
    .videoStyle {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
      aspect-ratio: 16/9; 
    }

  }