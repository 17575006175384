@media only screen and (max-width: 991px) {
    .web {
        display: flex;
        flex-direction: column;  
    }
    
    .screenflex {
        display: flex;
        flex-direction: column; /* Изменение направления блоков на вертикальное при ширине экрана до 991px */
    }

    .videosalesflex {
        display: flex;
        flex-direction: column;
    }
    .chatflex {
        display: flex;
        height: 50vh; /* Чатflex занимает 95% высоты экрана */
        margin: 5px; /* Внешний отступ 5px для chatflex */
        
    }
    .chatflexmoder {
        display: flex;
        height: 50vh; /* Чатflex занимает 95% высоты экрана */
        margin: 5px; /* Внешний отступ 5px для chatflex */
        
    }

    /* Другие стили для экранов с шириной до 991px */
  }
  
  @media only screen and (min-width: 992px) {
    .web {
        display: flex;
        flex-direction: column;  

    }
    .screenflex {
        display: flex;
        flex-direction: row; /* Изменение направления блоков на горизонтальное при ширине экрана более 991px */
        margin: 5px; /* Внешний отступ 5px для chatflex */
    }
    .videosalesflex {
        display: flex;
        flex: 0 0 65%; /* Видео и продажи занимают 70% ширины */
        flex-direction: column;
        margin: 5px; /* Внешний отступ 5px для chatflex */
      }
    .chatflex {
        display: flex;
        flex: 0 0 32%; /* Чат занимает 30% ширины */
        height: 93vh; /* Чатflex занимает 95% высоты экрана */
        margin: 5px; /* Внешний отступ 5px для chatflex */
    }
    .chatflexmoder {
        display: flex;
        flex: 0 0 32%; /* Чат занимает 30% ширины */
        height: 78vh; /* Чатflex занимает 95% высоты экрана */
        margin: 5px; /* Внешний отступ 5px для chatflex */
    }
    /* Другие стили для экранов с шириной более 991px */
  }