.autorization {
  display: flex; /* Используем inline-flex */
  justify-content: center;
  align-items: center; /* Центрирование по вертикали */
  height: 100vh;
}


.container {
  display: inline-flex; /* Используем inline-flex */
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #ffffff;
  height: auto; /* Автоматическая высота */
  width: auto;  /* Автоматическая ширина */
  padding: 20px; /* Отступ от бордюра */
}

  
  .form-container {
    text-align: center;
  }
  
  .tab-group {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .tab-header {
    padding: 10px 20px;
    background-color: #f2f2f2;
    cursor: pointer;
    border: 1px solid #cfcfcf;
    border-bottom: none;
  }
  
  .tab-header.active {
    background-color: #fff;
    border-bottom: 2px solid #1a237e;
  }
  
  .input-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-Width: 600;
    justify-content: space-between;
    margin: 10px 0;
    gap: 10px; /* Расстояние между элементами */
  }
  
  input {
    margin-bottom: 10px;
    padding: 8px;
    width: 200px;
    border: 1px solid #cfcfcf;
    border-radius: 5px;
  }
  
  .action-button {
    background-color: #1a237e;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .action-button:hover {
    background-color: #000051;
  }
  