@media only screen and (max-width: 991px) {
 .webchat {
    display: flex;
    flex-direction: column;  
    width: 100%;
    
 }
 .webchatname {
    display: flex;
    flex-direction: row;  
    width: 100%;  /* Растягиваем .webchatname по ширине родительского контейнера */
    padding: 3px 0; /* Оставляем 3 пикселя отступа сверху и снизу */  
 }
 .webchatinput {
    display: flex;
    flex-direction: column;  
    width: 100%;
    margin-top: auto;  /* Размещаем .webchatinput внизу родительского контейнера */
 }
 .webchatmessage {
   overflow-y: auto; /* Добавьте это свойство для прокручиваемого контейнера */
    display: flex;
    flex-direction: column;  
    width: 100%;
    flex-grow: 1;
 }
 .webchatinputbutton {
    display: flex;
    flex-direction: row; 
    
    flex-grow: 1;
    padding: 2px;  /* Устанавливаем внутренний отступ для .webchatmessage */
 }
 .webchatinput_100 {
    display: flex;
    width: 100%;
 }
 .webchatinputlabel {
    display: flex;
    /*padding: 2px;*/
 }
 .message-container {
   display: flex;
   flex-direction: column; 
   max-height: 98%;
   overflow-y: auto;
   /*padding: 2px;*/
   flex-shrink: 0; /* Запрещаем уменьшение контейнера */
   align-items: flex-start; /* Выравниваем содержимое контейнера вверху */
  }
  .message-container_moder {
   display: flex;
   flex-direction: column; 
   max-height: 98%;
   overflow-y: auto;
   /*padding: 2px;*/
   flex-shrink: 0; /* Запрещаем уменьшение контейнера */
   align-items: flex-start; /* Выравниваем содержимое контейнера вверху */
  }
   .message {
      display: flex;
      flex-direction: column; 
      font-size: 12px; /* Пример установки высоты шрифта в 16 пикселей */
      padding: 3px 0; /* Оставляем 3 пикселя отступа сверху и снизу */
      align-items: flex-start; /* Выравниваем содержимое вверху */
      margin-left: 3px;
   } 
   .moderbutton {
      display: flex;
      flex-direction: row; 

   }

    /* Другие стили для экранов с шириной до 991px */
  }
  
  @media only screen and (min-width: 992px) {
    .webchat {
        display: flex;
        flex-direction: column;  
        flex: 0 0 100%; /* Видео и продажи занимают 70% ширины */
     }
    
     .webchatname {
        display: flex;
        flex-direction: row;  
        width: 100%;
        padding: 3px 0; /* Оставляем 3 пикселя отступа сверху и снизу */
     }
     .webchatinput {
        display: flex;
        flex-direction: column;  
        width: 100%;
        margin-top: auto;  /* Размещаем .webchatinput внизу родительского контейнера */
     }
     .webchatmessage {
      overflow-y: auto; /* Добавьте это свойство для прокручиваемого контейнера */
        display: flex;
        flex-direction: column;  
        width: 100%;
        flex-grow: 1;
     }
     
     .webchatinputbutton {
        display: flex;
        flex-direction: row; 
        flex-grow: 1; /* Растягиваем .webchatinputbutton по ширине родительского контейнера */
        
        padding: 2px;  /* Устанавливаем внутренний отступ для .webchatmessage */
     }
     .webchatinput_100 {
        display: flex;
        width: 100%;
     }
     .webchatinputlabel {
        display: flex;
        /*padding: 2px;*/
     }
     .message-container {
      display: flex;
      flex-direction: column; 
      max-height: 98%;
      overflow-y: auto;
      /*padding: 2px;*/
      flex-shrink: 0; /* Запрещаем уменьшение контейнера */
      align-items: flex-start; /* Выравниваем содержимое контейнера вверху */
     }
     .message-container_moder {
      display: flex;
      flex-direction: column; 
      max-height: 90%;
      overflow-y: auto;
      /*padding: 2px;*/
      flex-shrink: 0; /* Запрещаем уменьшение контейнера */
      align-items: flex-start; /* Выравниваем содержимое контейнера вверху */
     }
      .message {
         display: flex;
         flex-direction: column; 
         font-size: 14px; /* Пример установки высоты шрифта в 16 пикселей */
         padding: 3px 0; /* Оставляем 3 пикселя отступа сверху и снизу */
         align-items: flex-start; /* Выравниваем содержимое вверху */
         margin-left: 3px;
      } 
      .moderbutton {
         display: flex;
         flex-direction: row; 

      }
 
     /* Другие стили для экранов с шириной более 991px */
  }