@media only screen and (max-width: 991px) {
    .moder_screen {
       display: flex;
       flex-direction: column;  
       width: 100%;

       
    }
    .moder_videobuttonreport {
       display: flex;
       flex-direction: column;  
       width: 50%;  /* Растягиваем .webchatname по ширине родительского контейнера */
       padding: 3px 0; /* Оставляем 3 пикселя отступа сверху и снизу */  
    }
    .moder_videobutton {
       display: flex;
       flex-direction: column;  
       
       
    }
    .w111ebchatmessage {
      overflow-y: auto; /* Добавьте это свойство для прокручиваемого контейнера */
       display: flex;
       flex-direction: column;  
       width: 100%;
       flex-grow: 1;
    }
    .w111ebchatinputbutton {
       display: flex;
       flex-direction: row; 
       
       flex-grow: 1;
       padding: 2px;  /* Устанавливаем внутренний отступ для .webchatmessage */
    }
    .w111ebchatinput_100 {
       display: flex;
       width: 100%;
    }
    .w111ebchatinputlabel {
       display: flex;
       /*padding: 2px;*/
    }
    .m111essage-container {
      display: flex;
      flex-direction: column; 
      max-height: 98%;
      overflow-y: auto;
      /*padding: 2px;*/
      flex-shrink: 0; /* Запрещаем уменьшение контейнера */
      align-items: flex-start; /* Выравниваем содержимое контейнера вверху */
     }
      .m111essage {
         display: flex;
         flex-direction: column; 
         font-size: 14px; /* Пример установки высоты шрифта в 16 пикселей */
         padding: 3px 0; /* Оставляем 3 пикселя отступа сверху и снизу */
         align-items: flex-start; /* Выравниваем содержимое вверху */
         margin-left: 3px;
      } 
   
       /* Другие стили для экранов с шириной до 991px */
     }
     
     @media only screen and (min-width: 992px) {
       .moder_screen {
           display: flex;
           flex-direction: row;  
           /*flex: 0 0 80%; /* Видео и продажи занимают 70% ширины */
           height: 80%;
        }
        .moder_videobuttonreport {
           display: flex;
           flex-direction: column;  
           width: 50%;
           padding: 3px 0; /* Оставляем 3 пикселя отступа сверху и снизу */
           
        }
        .moder_videobutton {
           display: flex;
           flex-direction: row;  
          
           
        }
        .w111ebchatmessage {
         overflow-y: auto; /* Добавьте это свойство для прокручиваемого контейнера */
           display: flex;
           flex-direction: column;  
           width: 100%;
           flex-grow: 1;
        }
        
        .w111ebchatinputbutton {
           display: flex;
           flex-direction: row; 
           flex-grow: 1; /* Растягиваем .webchatinputbutton по ширине родительского контейнера */
           
           padding: 2px;  /* Устанавливаем внутренний отступ для .webchatmessage */
        }
        .w111ebchatinput_100 {
           display: flex;
           width: 100%;
        }
        .w111ebchatinputlabel {
           display: flex;
           /*padding: 2px;*/
        }
        .m111essage-container {
         display: flex;
         flex-direction: column; 
         max-height: 98%;
         overflow-y: auto;
         /*padding: 2px;*/
         flex-shrink: 0; /* Запрещаем уменьшение контейнера */
         align-items: flex-start; /* Выравниваем содержимое контейнера вверху */
        }
         .m111essage {
            display: flex;
            flex-direction: column; 
            font-size: 16px; /* Пример установки высоты шрифта в 16 пикселей */
            padding: 3px 0; /* Оставляем 3 пикселя отступа сверху и снизу */
            align-items: flex-start; /* Выравниваем содержимое вверху */
            margin-left: 3px;
         } 
    
        /* Другие стили для экранов с шириной более 991px */
     }