body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
}

.containeraudio {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: lightblue; /* Голубой фон */
}

.post {
    width: 80%;
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.date {
    font-size: 1rem;
    color: gray;
    margin-bottom: 10px;
}

.text {
    margin-bottom: 20px;
}

.audio-player {
    /* Стили для проигрывателя аудио могут быть добавлены здесь */
}
